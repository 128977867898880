import { graphql } from 'gatsby';
import * as React from 'react';
import { Helmet } from 'react-helmet';
import Hero from '../components/Hero';
import Layout from '../components/Layout';
import ReleaseList from '../components/ReleaseList';

const StartPage = (props) => {
	return (
		<Layout>
            <Helmet htmlAttributes={{lang: 'en'}}>
                <title>Ballistix - making metal music</title>
                <meta name="description" content="Home of the metal band Ballistix" />
            </Helmet>
            <Hero />
            <ReleaseList />
		</Layout>
	);
};

export default StartPage;

export const pageQuery = graphql`
	query {
		startPage: sanityStartPage {
			headline
		}
	}
`;

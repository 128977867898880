import React from 'react'
import * as styles from './Hero.module.scss'

const Hero = () => (
    <div className={styles.wrapper}>
        <div className={styles.content}>
            <h2 className={styles.subheading}>Making metal music</h2>
        </div>
    </div>
)

export default Hero